import React, { Component, Fragment } from 'react';
import { autobind } from 'core-decorators';
import dayjs from 'dayjs';
import _ from 'lodash';

// Rexlabs
import Box from '@rexlabs/box';
import { withModel } from '@rexlabs/model-generator';

// Local
import { PADDINGS } from 'theme';
import { checkDate } from 'utils/date';
import Dialog from 'view/components/dialog';
import { withErrorDialog } from 'src/hocs/with-error-dialog';
import { Form, FormField, ReactForms } from 'view/components/form';
import { TextButton, DefaultButton } from 'view/components/button';
import { DialogBody } from 'components/text/dialog-body';
import { Select } from 'view/components/input/select';
import adminIdentityDocumentTypeModel from 'data/models/entities/admin-identity-document-types';

// Shared
import { ButtonBar } from 'shared/components/button-bar';
import { createValidationRules } from 'shared/utils/form';
import { TextArea, TextInput } from '@rexlabs/text-input';
import { Grid, Column } from 'shared/components/grid';
import Checkbox from 'src/view/components/input/checkbox';
import { FileUploadButtonInput } from 'src/view/components/input/file-upload-button-input';
import { ACCEPTED_EXTENSIONS } from 'components/documents-table/constants';
import { COLORS } from 'src/theme';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

function validateDate(newValue) {
  if (!newValue) {
    return null;
  }
  return checkDate(newValue);
}

@withErrorDialog
@withModel(adminIdentityDocumentTypeModel)
@autobind
class AddIdentificationDialog extends Component {
  static defaultProps = {
    documentRecord: null
  };

  constructor(props) {
    super(props);

    /**
     * Used to hold the ID Types from Wings and the status of said API call.
     *
     * @name AddIdentificationDialog#state
     * @type {Object}
     * @default { idTypes: [], status: 'loading' }
     * @property {Array} idTypes The ID Types that the user has defined in the admin area for ID Types
     * @property {string} status The current status used to show if we have loaded the ID Types or not
     */
    this.state = {
      idTypes: [],
      status: 'loading'
    };
  }

  componentDidMount() {
    const { adminIdentityDocumentTypes, errorDialog } = this.props;

    adminIdentityDocumentTypes
      .fetchAll({
        criteria: [
          {
            name: 'is_hidden',
            type: '=',
            value: '0'
          }
        ]
      })
      .then((response) => {
        this.setState({ idTypes: response.rows, status: 'loaded' });
      })
      .catch((error) => {
        this.setState({ status: 'loaded' });
        errorDialog.open(error);
      });
  }

  handleSubmit(values) {
    const {
      documentRecord,
      errorDialog,
      closeDialog,
      onClose,
      type: proofTypeId
    } = this.props;

    const { type_id: typeId, ...validValues } = values;

    const payload = {
      ...documentRecord,
      ...validValues,
      type: typeId.value,
      proof_type: {
        id: proofTypeId
      }
    };

    const submitAction = Promise.resolve();

    return submitAction
      .then(() => {
        onClose({
          data: payload
        });
        closeDialog();
      })
      .catch(errorDialog.open);
  }

  getInitialValues() {
    const { documentRecord } = this.props;
    const { idTypes } = this.state;

    if (!documentRecord || !idTypes.length) {
      return {};
    }

    const currentChosenType = idTypes.find(
      (type) => type.id === _.get(documentRecord, 'type.id')
    );

    return {
      ...documentRecord,
      type_id: {
        value: currentChosenType,
        label: currentChosenType.name
      }
    };
  }

  handleValidation(values, extra) {
    if (_.get(extra, 'submitCount') < 1) {
      return {};
    }

    const { type_id: idType } = values;

    // HACK: the validation rules below use the "|" pipe character to seperate rules, so using it as part of a regex throws errors
    // custom validation rule regex_custom in `shared/utils/form.js` puts the pipes back before testing the regex against the given value
    const referenceRegExp = _.get(idType, 'value.validation_regex')?.replace(
      /\|/g,
      '____$____'
    );
    const issueDateRequired = _.get(idType, 'value.issue_date_is_required');
    const expiryDateRequired = _.get(idType, 'value.expiry_date_is_required');
    const issueDateValidation = _.get(
      idType,
      'value.issue_date_validation_rule'
    );
    const expiryDateValidation = _.get(
      idType,
      'value.expiry_date_validation_rule'
    );

    const removeTimeAndFormat = (dateWithTime) => {
      if (!dateWithTime) {
        return null;
      }
      if (typeof dateWithTime !== 'string') {
        return dateWithTime.format('DD/MM/YYYY');
      }
      return dateWithTime.replace(/-/g, '/');
    };

    // Remove time as we don't care about it
    const today = removeTimeAndFormat(dayjs());

    // Convert current form value dates
    const formattedValues = {
      ...values,
      issue_date: removeTimeAndFormat(values.issue_date),
      expiry_date: removeTimeAndFormat(values.expiry_date)
    };

    // Setup date validation maps so we can validate the dates as expected
    const monthAgoFromToday = removeTimeAndFormat(dayjs().subtract(1, 'month'));
    const threeMonthsAgoFromToday = removeTimeAndFormat(
      dayjs().subtract(3, 'month')
    );
    const sixMonthsAgoFromToday = removeTimeAndFormat(
      dayjs().subtract(6, 'month')
    );
    const twelveMonthsAgoFromToday = removeTimeAndFormat(
      dayjs().subtract(12, 'month')
    );

    const monthFromToday = removeTimeAndFormat(dayjs().add(1, 'month'));
    const threeMonthsFromToday = removeTimeAndFormat(dayjs().add(3, 'month'));
    const sixMonthsFromToday = removeTimeAndFormat(dayjs().add(6, 'month'));
    const twelveMonthsFromToday = removeTimeAndFormat(dayjs().add(12, 'month'));

    const issueDateValidationMap = {
      before_today: `date_before:${today}`,
      '1_months': `date_before:${today}|date_after:${monthAgoFromToday}`,
      '3_months': `date_before:${today}|date_after:${threeMonthsAgoFromToday}`,
      '6_months': `date_before:${today}|date_after:${sixMonthsAgoFromToday}`,
      '12_months': `date_before:${today}|date_after:${twelveMonthsAgoFromToday}`
    };

    const expiryDateValidationMap = {
      after_today: `date_after:${today}`,
      '1_months': `date_after:${monthFromToday}`,
      '3_months': `date_after:${threeMonthsFromToday}`,
      '6_months': `date_after:${sixMonthsFromToday}`,
      '12_months': `date_after:${twelveMonthsFromToday}`
    };

    // Map issue & expiry date validation rules that have been defined by the user
    const issueDateValidationRules = [];
    issueDateRequired && issueDateValidationRules.push('required');
    issueDateValidation &&
      issueDateValidationRules.push(
        issueDateValidationMap[issueDateValidation.id]
      );

    const expiryDateValidationRules = [];
    expiryDateRequired && expiryDateValidationRules.push('required');
    expiryDateValidation &&
      expiryDateValidationRules.push(
        expiryDateValidationMap[expiryDateValidation.id]
      );

    const formattedRules = {
      type_id: ['required', 'type'],
      reference: [
        referenceRegExp
          ? `required|document_type_regex:${referenceRegExp}`
          : 'required',
        'reference'
      ],
      ...(issueDateRequired || issueDateValidation
        ? {
            issue_date: [issueDateValidationRules.join('|'), 'issue date']
          }
        : {}),
      ...(expiryDateRequired || expiryDateValidation
        ? {
            expiry_date: [expiryDateValidationRules.join('|'), 'expiry date']
          }
        : {})
    };

    // Return the validatorjs errors
    return createValidationRules(formattedRules)(formattedValues);
  }

  render() {
    const { closeDialog, documentRecord } = this.props;
    const { idTypes, status } = this.state;

    const typeOptions = idTypes.map((idType) => {
      return {
        label: idType.name,
        value: idType
      };
    });

    const isLoading = status !== 'loaded';
    const title = documentRecord ? 'Edit Identification' : 'Add Identification';

    return (
      <Dialog
        title={title}
        width={500}
        height={250}
        closeDialog={closeDialog}
        isLoading={isLoading}
        showLoadingSpinner
      >
        <ReactForms
          validateOnMount
          handleSubmit={this.handleSubmit}
          validate={this.handleValidation}
          initialValues={this.getInitialValues()}
        >
          {({ values, submitForm, isSubmitting, setFieldValue }) => {
            const idType = _.get(values, 'type_id.value');
            const description = _.get(values, 'type_id.value.description');
            const showIssueDate = _.get(
              values,
              'type_id.value.issue_date_is_recordable'
            );
            const showExpiryDate = _.get(
              values,
              'type_id.value.expiry_date_is_recordable'
            );
            const issueDateRequired = _.get(
              values,
              'type_id.value.issue_date_is_required'
            );
            const expiryDateRequired = _.get(
              values,
              'type_id.value.expiry_date_is_required'
            );

            return (
              <Form>
                <FormField
                  name='type_id'
                  label={'type*'}
                  Input={Select}
                  inputProps={{
                    valueAsObject: true,
                    options: typeOptions,
                    groupOptions: ({ value }) => ({
                      value: value.category.id,
                      label: value.category.text
                    })
                  }}
                  sendImmediate
                />
                {!!idType && (
                  <Fragment>
                    <Box pt={PADDINGS.S}>
                      <DialogBody italic light>
                        {description}
                      </DialogBody>
                    </Box>
                    <FormField
                      name='reference'
                      label={'reference*'}
                      Input={TextInput}
                    />
                    <Grid>
                      {showIssueDate && (
                        <Column width={showExpiryDate ? 6 : 12}>
                          <FormField
                            name='issue_date'
                            label={`issue date${issueDateRequired ? '*' : ''}`}
                            Input={TextInput}
                            inputProps={{
                              placeholder: 'DD/MM/YYYY'
                            }}
                            validate={validateDate}
                          />
                        </Column>
                      )}
                      {showExpiryDate && (
                        <Column width={showIssueDate ? 6 : 12}>
                          <FormField
                            name='expiry_date'
                            label={`expiry date${
                              expiryDateRequired ? '*' : ''
                            }`}
                            Input={TextInput}
                            inputProps={{
                              placeholder: 'DD/MM/YYYY',
                              disabled: values.does_not_expire
                            }}
                            validate={validateDate}
                          />
                          <FormField
                            name='does_not_expire'
                            Input={Checkbox}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setFieldValue('expiry_date', '');
                              }
                            }}
                            inputProps={{
                              label: 'does not expire'
                            }}
                          />
                        </Column>
                      )}
                    </Grid>
                    <FormField
                      name='notes'
                      label={'note'}
                      Input={TextArea}
                      inputProps={{
                        placeholder:
                          'e.g. Optional not about this identification'
                      }}
                    />
                    {hasFeatureFlags('compliance') && (
                      <FormField
                        label={
                          <>
                            <label>upload certificate </label>
                            <label style={{ color: COLORS.PRIMARY.SAND_DARK }}>
                              &mdash; optional
                            </label>
                          </>
                        }
                        name='file'
                        Input={FileUploadButtonInput}
                        inputProps={{
                          acceptExtensions: ACCEPTED_EXTENSIONS,
                          acceptTypes: '*',
                          isArray: false
                        }}
                      />
                    )}
                  </Fragment>
                )}

                <Box pt={PADDINGS.M}>
                  <ButtonBar isLoading={isSubmitting} hasPadding={false}>
                    <TextButton key='cancel' blue onClick={closeDialog}>
                      Cancel
                    </TextButton>
                    <DefaultButton key='save' onClick={submitForm}>
                      Done
                    </DefaultButton>
                  </ButtonBar>
                </Box>
              </Form>
            );
          }}
        </ReactForms>
      </Dialog>
    );
  }
}

export default AddIdentificationDialog;
