import React from 'react';
import { api } from 'shared/utils/api-client';
import ExternalLinkRedirect from 'view/components/external-link-redirect';
import { withModel } from '@rexlabs/model-generator';
import sessionModel, { SessionModel } from 'data/models/custom/session';

function RexHomeSettingsRedirect({ session }: { session: SessionModel }) {
  return (
    <ExternalLinkRedirect
      fetchRedirectUrl={() => api.post('Listings::getRexHomeAgencySettingsUrl')}
      title={'Rex Home Settings'}
      hasPermission={session.checkUserHasPermission(
        'admin_application.access_section'
      )}
      showBackButton={false}
      missingPermissionMessage={
        'To use Rex Home Settings you need the "Access Administration Settings" privilege. Contact your Rex administrator to get access.'
      }
    />
  );
}

export default withModel(sessionModel)(RexHomeSettingsRedirect);
