import { ComplianceStatus } from '../data/compliance-query-model';

interface AmlRecord {
  status?: { id: string };
  check_type?: { id: string };
}

interface SubstantiationRecord {
  expiry_date?: string | null;
  status?: { id: string };
}

export const getAmlStatus = (
  amlRecord: AmlRecord | undefined
): ComplianceStatus['status'] => {
  const status = amlRecord ? amlRecord?.status?.id : 'incomplete';

  // Default status definitions for manual checks with IDs matching STATUS_CONFIG
  const statusDefs = [
    {
      id: 'need_verification',
      label: 'AML check incomplete'
    },
    {
      id: 'completed',
      label: 'AML check complete'
    },
    {
      id: 'expired',
      label: 'AML check expired'
    }
  ];

  // If it's an external check, use the submission status definitions
  if (amlRecord && amlRecord.check_type?.id === 'external') {
    // Map the status directly to the STATUS_CONFIG keys
    const statusId =
      status === 'is_expired'
        ? 'expired'
        : status === 'complete'
        ? 'completed'
        : status;

    return (
      [
        {
          id: 'incomplete',
          label: 'AML check incomplete'
        },
        {
          id: 'submitted',
          label: 'AML check submitted'
        },
        {
          id: 'completed',
          label: 'AML check complete'
        },
        {
          id: 'updates_required',
          label: 'AML updates required'
        },
        {
          id: 'expired',
          label: 'AML check expired'
        }
      ].find((def) => def.id === statusId) || statusDefs[0]
    );
  }

  // For manual checks, map the status directly
  const statusId =
    status === 'is_expired'
      ? 'expired'
      : status === 'complete'
      ? 'completed'
      : 'need_verification';

  return statusDefs.find((def) => def.id === statusId) || statusDefs[0];
};

const checkExpiry = (expiryDate: string | null | undefined) => {
  if (!expiryDate) return false;
  const expiry = new Date(expiryDate);
  return expiry < new Date();
};

export const getSubstantiationStatus = (
  substantiationRecords: SubstantiationRecord[]
): ComplianceStatus['status'] => {
  // Map statuses, checking for expiry
  const statuses = substantiationRecords
    .map((s) => {
      if (checkExpiry(s.expiry_date)) {
        return 'expired';
      }
      return s.status?.id;
    })
    .filter(Boolean);

  // Status definitions with IDs matching STATUS_CONFIG
  const statusDefs = [
    {
      id: 'incomplete',
      label: 'To be substantiated'
    },
    {
      id: 'in_progress',
      label: 'Substantiation in progress'
    },
    {
      id: 'completed',
      label: 'Substantiation successful'
    },
    {
      id: 'rejected',
      label: 'Substantiation failed'
    },
    {
      id: 'rejected',
      label: 'Substantiation refused'
    },
    {
      id: 'expired',
      label: 'Substantiation expired'
    }
  ];

  // Determine the most relevant status
  // Priority: successful > in_progress > failed > refused > expired > incomplete
  if (statuses.includes('successful')) {
    return statusDefs.find((def) => def.label === 'Substantiation successful')!;
  } else if (statuses.includes('in_progress')) {
    return statusDefs.find(
      (def) => def.label === 'Substantiation in progress'
    )!;
  } else if (statuses.includes('failed')) {
    return statusDefs.find((def) => def.label === 'Substantiation failed')!;
  } else if (statuses.includes('refused')) {
    return statusDefs.find((def) => def.label === 'Substantiation refused')!;
  } else if (statuses.includes('expired')) {
    return statusDefs.find((def) => def.label === 'Substantiation expired')!;
  }

  // Default to incomplete
  return statusDefs.find((def) => def.label === 'To be substantiated')!;
};
