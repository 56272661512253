import {
  DefaultError,
  QueryClient,
  QueryFunction,
  QueryKey,
  queryOptions,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // queries are never marked stale and won't be refetched
      gcTime: 0, // when queries are unused, they will be immediately removed from the cache
      retry: false
    }
  }
});

export function mutationOptions<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
>(options: UseMutationOptions<TData, TError, TVariables, TContext>) {
  return options;
}

export { queryOptions } from '@tanstack/react-query';

/**
 * Utility component for using React-Query in class components
 */

// This type helps with DataTag inference
type DataTag<TQueryKey extends QueryKey, TData> = TQueryKey;

interface UseQueryWrapperProps<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> {
  // The query key - renamed from 'key' to 'queryKey' to match TanStack terminology
  queryKey: TQueryKey | DataTag<TQueryKey, TData>;
  // The query function
  queryFn: QueryFunction<TQueryFnData, TQueryKey>;
  // Optional query options
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >;
  // Render prop function that receives the query result with specific types
  children: (result: UseQueryResult<TData, TError>) => React.ReactNode;
}

export function UseQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queryKey,
  queryFn,
  options,
  children
}: UseQueryWrapperProps<
  TQueryFnData,
  TError,
  TData,
  TQueryKey
>): React.ReactElement {
  const queryResult = useQuery<TQueryFnData, TError, TData, TQueryKey>({
    queryKey,
    queryFn,
    ...options
  });

  return <>{children(queryResult)}</>;
}
