import types from 'prop-types';
import React, { Fragment, PureComponent } from 'react';

import { DefaultButton, TextButton } from 'view/components/button';
import ButtonBar from './button-bar';

class SaveCancelButtonBar extends PureComponent {
  static propTypes = {
    saveButtonText: types.string,
    isLoading: types.bool,
    onSave: types.func,
    onCancel: types.func
  };

  render() {
    const {
      onCancel,
      onSave,
      isDisabled,
      isCancelDisabled,
      saveLabel,
      cancelLabel,
      blue,
      renderLeft,
      ...props
    } = this.props;

    return (
      <ButtonBar {...props}>
        {renderLeft && <Fragment left>{renderLeft()}</Fragment>}
        <TextButton
          key='cancel'
          blue
          onClick={onCancel}
          isDisabled={isCancelDisabled}
        >
          {cancelLabel || 'Cancel'}
        </TextButton>
        <DefaultButton
          isDisabled={isDisabled}
          key='save'
          red={!blue}
          blue
          onClick={onSave}
        >
          {saveLabel || 'Save'}
        </DefaultButton>
      </ButtonBar>
    );
  }
}

export default SaveCancelButtonBar;
