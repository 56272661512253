import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { RecordListTable } from 'components/record-list-screen/table';
import { StateView } from 'components/record-list-screen/state-view';
import Spinner from 'shared/components/spinner';
import { useDialog } from 'hooks/use-dialog';
import { TenancyApplicationItem } from 'features/tenancy-applications/data/types';
import {
  ColumnConfig,
  ListActionMenuProps,
  Selection
} from 'components/record-list-screen/types';
import dayjs from 'dayjs';
import { useRegion } from 'hooks/use-region';
import { formatCurrency } from 'utils/formatters';
import { useApplicationPermissions } from '../hooks/use-application-permissions';
import { useModelState } from '@rexlabs/model-generator';
import sessionModel from 'data/models/custom/session';
import { LettingsHubDialogTabs } from 'features/the-lettings-hub/dialogs/lettings-hub-dialog/lettings-hub-dialog';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { ListingStub } from 'data/models/entities/listings';

interface ApplicationsTableProps {
  applicationList: TenancyApplicationItem[];
  setApplicationList: Dispatch<SetStateAction<TenancyApplicationItem[]>>;
  isLoading: boolean;
  selection: Selection;
  setSelection: Dispatch<SetStateAction<Selection>>;
  listing: ListingStub;
}

function ApplicationsTable({
  applicationList,
  setApplicationList,
  isLoading,
  selection,
  setSelection,
  listing
}: ApplicationsTableProps) {
  const region = useRegion();
  const dollarSymbol = region.financial.currency.symbol;
  const editApplicationDialog = useDialog('addEditApplication');
  const deleteApplication = useDialog('deleteApplication');
  const updateStatus = useDialog('updateApplicationStatus');
  const theLettingsHubDialog = useDialog('theLettingsHub');
  const offerMailMerge = useDialog('offerMailMerge');
  const editMailMerge = useDialog('editMailMerge');
  const auditLogs = useDialog('auditLogs');
  const esignList = useDialog('esignList');
  const theLettingsHubSyncApplicantDialog = useDialog(
    'theLettingsHubSyncApplicant'
  );
  const session = useModelState(sessionModel);
  const lettingsHubConnections = session.third_party_extensions.filter(
    (extension) => extension.service_type_id === 'the_lettings_hub'
  );
  const docusignConnections = session.third_party_extensions
    .filter((extension) => extension.service_type_id === 'docusign')
    .map((connection) => ({
      id: connection.id,
      name: connection.connection_name,
      service: {
        name: connection.definition.name
      }
    }));
  const { getTenancyRights } = useApplicationPermissions();

  const onApplicationChange = (updatedApplication: TenancyApplicationItem) => {
    setApplicationList((prevList) =>
      prevList.map((app) => {
        if (app.id === updatedApplication.id) return updatedApplication;
        return app;
      })
    );
  };

  const columns: ColumnConfig<TenancyApplicationItem>[] = useMemo(
    () => [
      {
        id: 'tenant',
        label: 'primary tenant',
        selector: (row) =>
          row.related.listing_application_tenants?.[0]?.contact?.name,
        forced: true,
        cellProps: {
          items: (row: TenancyApplicationItem) => {
            const { canUpdate, canView, canPurge } = getTenancyRights(
              row.security_user_rights
            );
            const actions: ListActionMenuProps[] = [];

            if (canUpdate || canView)
              actions.push({
                label: canUpdate ? 'View / Edit' : canView ? 'View' : '',
                onClick: ({ data }) =>
                  editApplicationDialog.open({
                    application: data,
                    onApplicationChange
                  })
              });

            if (canView) {
              actions.push({
                label: 'Mail Merge',
                onClick: () => {
                  offerMailMerge.open({
                    callback: async ({ medium }) => {
                      // HACK: the transition for shell to classic dialog is pretty ugly with the backdrop
                      // flashing etc, this timeout makes it at least a little bit nicer!
                      setTimeout(
                        () =>
                          editMailMerge.open({
                            data: {
                              mode: 'listing',
                              medium: medium.value,
                              data: {
                                property: {
                                  id: listing.property.id,
                                  name: listing.property.system_search_key
                                },
                                listing: {
                                  id: listing._id,
                                  name: listing.property.system_search_key
                                },
                                tenancy_application: row
                              }
                            },
                            options: {
                              title: `Send ${medium.label}`
                            }
                          }),
                        400
                      );
                    }
                  });
                }
              });
              if (docusignConnections.length > 0) {
                actions.push(
                  ...docusignConnections.map((connection) => ({
                    label: `Esign via ${connection.name}`,
                    onClick: ({ data }) =>
                      esignList.open({
                        connection,
                        record: {
                          recordId: row.id,
                          recordType: 'TenancyApplications',
                          record_name: listing.property.system_search_key,
                          tenancyApplication: data
                        }
                      })
                  }))
                );
              }
            }

            if (canUpdate)
              actions.push({
                label: 'Update Status',
                onClick: ({ data }) =>
                  updateStatus.open({
                    application: data,
                    onApplicationChange
                  })
              });

            if (row.application_status?.id === 'received' && canPurge)
              actions.push({
                label: 'Delete',
                onClick: ({ data }) =>
                  deleteApplication.open({
                    application: data,
                    callback: (application: TenancyApplicationItem) =>
                      setApplicationList((prev) =>
                        prev.filter(
                          (prevApplication) =>
                            prevApplication.id !== application.id
                        )
                      )
                  })
              });

            if (hasFeatureFlags('the_lettings_hub_integration')) {
              lettingsHubConnections.forEach((connection) => {
                actions.push({
                  label: connection.connection_name,
                  isGroupHeading: true
                });

                actions.push({
                  label: 'Sync Application',
                  onClick: () =>
                    theLettingsHubSyncApplicantDialog.open({
                      application: row,
                      connectionId: connection.id
                    })
                });

                actions.push({
                  label: 'View Reports',
                  onClick: () => {
                    theLettingsHubDialog.open({
                      application: row,
                      connectionId: connection.id,
                      initialTab: LettingsHubDialogTabs.Reports
                    });
                  }
                });

                actions.push({
                  label: 'View Notes',
                  onClick: () =>
                    theLettingsHubDialog.open({
                      application: row,
                      connectionId: connection.id,
                      initialTab: LettingsHubDialogTabs.Notes
                    })
                });

                actions.push({
                  label: 'View Audit',
                  onClick: () =>
                    theLettingsHubDialog.open({
                      application: row,
                      connectionId: connection.id,
                      initialTab: LettingsHubDialogTabs.Audit
                    })
                });
              });
            }

            actions.push({
              label: 'View Audit Logs',
              onClick: () => {
                auditLogs.open({
                  id: row.id,
                  data: row,
                  serviceName: 'TenancyApplications'
                });
              }
            });

            return actions;
          }
        }
      },
      {
        id: 'status',
        label: 'status',
        selector: (row) => row.application_status?.text ?? '-',
        forced: true
      },
      {
        id: 'affordability',
        label: 'Affordability',
        selector: (row) =>
          row.system_affordability_percentage
            ? `${row.system_affordability_percentage}%`
            : '-',
        forced: true
      },
      {
        id: 'offer',
        label: 'offer',
        selector: (row) =>
          dollarSymbol +
          formatCurrency(row.offer_amount) +
          ' / ' +
          row.offer_amount_period?.text.toLowerCase(),
        forced: true
      },
      {
        id: 'start_date',
        label: 'start date',
        selector: (row) => dayjs(row.start_date).format('D MMM YYYY'),
        forced: true
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editApplicationDialog,
      updateStatus,
      dollarSymbol,
      listing,
      lettingsHubConnections,
      docusignConnections
    ]
  );

  const disabledSelections = useMemo(() => {
    return applicationList
      .filter(
        (application) => application.application_status?.id === 'unsuccessful'
      )
      .map((application) => application.id);
  }, [applicationList]);

  return (
    <RecordListTable
      items={applicationList}
      columns={columns}
      visibleColumns={columns.map((c) => c.id)}
      setVisibleColumns={() => null}
      selection={selection}
      setSelection={setSelection}
      disabledSelections={disabledSelections}
      hasSelection={true}
      setOrderBy={() => null}
      isLoading={isLoading}
      LoadingView={() => (
        <StateView noPadding>
          <Spinner small dark />
        </StateView>
      )}
      EmptyView={() => <StateView noPadding>No applications added</StateView>}
      variant={'compact'}
    />
  );
}

export default ApplicationsTable;
