import { apiResult } from 'shared/utils/api-client/api-result';
import { mutationOptions, queryOptions } from 'src/lib/react-query';
import { standardFormComponentMap } from 'src/view/components/schema-forms/schema-form-fields';
import { SchemaFormDefinition } from 'src/view/components/schema-forms/schema-form-renderer';
import { UserItem } from 'data/models/value-lists/account-users';
import {
  getAmlStatus,
  getSubstantiationStatus
} from '../utils/compliance-statuses';

export interface ComplianceStatus {
  compliance_entry_id: string;
  label: string;
  type: string;
  metadata?: any;
  status: {
    id: string;
    label: string;
  };
}

export interface ComplianceEntry {
  system_ctime: number;
  system_modtime: number;
  system_record_state: string;
  parent_object_id: number;
  sub_type_record_id: number;
  type_id: string;
  details: Record<string, any>;
  file: {
    url: string;
    uri: string;
    name: string;
  } | null;
  etag: string;
  system_modified_user: UserItem;
  system_created_user: UserItem;
  schema: SchemaFormDefinition<typeof standardFormComponentMap>;
  verification: {
    id: string;
    system_ctime: number;
    verification_status: {
      id: string;
      text: string;
    };
    verified_date: string | null;
    verification_source: {
      id: string;
      text: string;
    } | null;
    verified_by_user_id: number | null;
    verified_by_name: string | null;
    verification_message: string | null;
  };
  source: {
    id: string;
    text: string;
  };
  parent_object_type: {
    id: string;
    text: string;
  };
  sub_type: {
    id: string;
    text: string;
  };
  id: number;
}

const modelKey = 'compliance';

const serviceFieldMap = {
  listings: 'listing_id',
  contacts: 'contact_id',
  properties: 'property_id'
};

const parentObjectTypeIdMap = {
  listings: 'listing',
  contacts: 'contact',
  properties: 'property'
};

export const complianceQueries = {
  getComplianceStatuses(
    params: { recordId: string; service?: string },
    onError?: (error: Error) => void
  ) {
    const serviceName = params.service || 'Contacts';
    const { service, ...data } = params;

    const apiData = {
      [serviceFieldMap[serviceName.toLowerCase()]]: params.recordId
    };

    return queryOptions({
      queryKey: [modelKey, 'getComplianceStatuses', serviceName, data],
      queryFn: async () => {
        if (serviceName === 'Contacts') {
          return Promise.all([
            apiResult<ComplianceStatus[]>(
              `${serviceName}::getComplianceStatuses`,
              apiData
            ),
            apiResult<{ rows: any[] }>(`Substantiations::search`, {
              criteria: [
                { name: 'related_contact.id', value: params.recordId },
                { name: 'system_record_state', value: 'active' }
              ]
            }),
            apiResult<{ rows: any[] }>(
              `ContactAntiMoneyLaunderingChecks::search`,
              {
                criteria: [{ name: 'contact_id', value: params.recordId }]
              }
            )
          ]).then(([complianceStatuses, substantiations, aml]) => {
            const amlRecord = aml.rows[0];

            const amlStatus = getAmlStatus(amlRecord);

            const substantiationStatus = getSubstantiationStatus(
              substantiations.rows || []
            );

            return [
              {
                label: 'AML',
                type: 'aml',
                status: amlStatus
              },
              {
                label: 'Substantiation',
                type: 'substantiation',
                status: substantiationStatus,
                metadata: {
                  substantiations: substantiations.rows
                }
              },
              ...complianceStatuses
            ] as ComplianceStatus[];
          });
        }

        try {
          return await apiResult<ComplianceStatus[]>(
            `${serviceName}::getComplianceStatuses`,
            apiData
          );
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      }
    });
  },
  search(
    params: { recordId: string; service?: string; typeId?: string },
    onError?: (error: Error) => void
  ) {
    const serviceName = params.service || 'Contacts';
    const { service, ...data } = params;

    const apiData = {
      criteria: [
        {
          name: 'parent_object_type_id',
          value: parentObjectTypeIdMap[serviceName.toLowerCase()]
        },
        { name: 'parent_object_id', value: params.recordId },
        ...(params.typeId ? [{ name: 'type_id', value: params.typeId }] : [])
      ]
    };

    return queryOptions({
      queryKey: [modelKey, 'search', serviceName, apiData],
      queryFn: async () => {
        try {
          return await apiResult<{ rows: ComplianceEntry[] }>(
            `ComplianceEntries::search`,
            apiData
          );
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      }
    });
  },
  read(
    params: { complianceEntryId: string },
    onError?: (error: Error) => void
  ) {
    return queryOptions({
      throwOnError: false,
      queryKey: [modelKey, 'read', params.complianceEntryId],
      queryFn: async () => {
        try {
          return await apiResult<ComplianceEntry>(`ComplianceEntries::read`, {
            id: params.complianceEntryId
          });
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      }
    });
  },
  update(
    params: {
      complianceEntryId: string;
      complianceType: string;
      recordId: string;
      service: string;
    },
    onError?: (error: Error) => void
  ) {
    return mutationOptions({
      onError,
      mutationKey: [modelKey, 'update', params.complianceEntryId],
      mutationFn: (values: any) =>
        apiResult<ComplianceEntry>(`ComplianceEntries::update`, {
          data: {
            id: params.complianceEntryId,
            parent_object_type_id:
              parentObjectTypeIdMap[params.service.toLowerCase()],
            type_id: params.complianceType,
            parent_object_id: params.recordId,
            details: values,
            source_id: 'crm'
          }
        })
    });
  },
  getSchemaForType(
    params: { type_id: string },
    onError?: (error: Error) => void
  ) {
    return queryOptions({
      queryKey: [modelKey, 'getSchemaForType', params.type_id],
      queryFn: async () => {
        try {
          return await apiResult<
            SchemaFormDefinition<typeof standardFormComponentMap>
          >(`ComplianceEntries::getSchemaForType`, {
            type_id: params.type_id
          });
        } catch (error) {
          if (onError) {
            onError(error as Error);
          }
        }
      }
    });
  },
  create(
    params: {
      complianceType: string;
      recordId: string;
      service: string;
    },
    onError?: (error: Error) => void
  ) {
    const service = params.service.toLowerCase();

    return mutationOptions({
      onError,
      mutationKey: [
        modelKey,
        'create',
        params.complianceType,
        params.recordId,
        service
      ],
      mutationFn: (values: any) => {
        const data = {
          parent_object_type_id: parentObjectTypeIdMap[service],
          type_id: params.complianceType,
          parent_object_id: params.recordId,
          details: values,
          source_id: 'crm'
        };

        return apiResult<ComplianceEntry>(`ComplianceEntries::create`, {
          data
        });
      }
    });
  }
} as const;
