/* eslint-disable max-lines */
import React, { PureComponent } from 'react';

import { autobind } from 'core-decorators';
import dayjs from 'dayjs';
import SaveCancelButtonBar from 'view/components/button-bar/save-cancel';
import Dialog from 'view/components/dialog';
import { withModel } from '@rexlabs/model-generator';
import _ from 'lodash';
import { transformUpdateData } from 'shared/utils/api-client';

import { Form, FormField, HiddenField, ReactForms } from 'view/components/form';
import { createValidationRules } from 'shared/utils/form';
import { styled, StyleSheet, StylesProvider } from '@rexlabs/styling';
import { COLORS, PADDINGS } from 'src/theme';
import { withErrorDialog } from 'src/hocs/with-error-dialog';

import { TabsStateful } from 'view/components/tabs';
import { getArgs } from 'data/queries/id-verifications';

import idVerificationRequirementCategoryCompanyModel from 'data/models/system-lists/id-verification-requirement-category-company';
import idVerificationRequirementCategoryPersonModel from 'data/models/system-lists/id-verification-requirement-category-person';
import adminIdentityRequirementsCompaniesModel from 'data/models/entities/admin-identity-requirements-company';
import adminIdentityRequirementsPeopleModel from 'data/models/entities/admin-identity-requirements-people';
import identityVerificationsModel from 'data/models/entities/contact-identity-verifications';
import sessionModel from 'data/models/custom/session';
import PaddingBox from 'view/components/padding-box';
import { ValueListSelect } from 'view/components/input/select';
import {
  getOfficeholdersAmlCompleted,
  getOfficeholdersIdVerification
} from 'view/dialogs/verifications/id-verification/utils';

import DetailsTab from './tabs/details';

import ConfirmationDialog from 'view/dialogs/confirmation';
import NoticeDialog from 'view/dialogs/notice';
import { withDialog } from 'shared/hocs/with-dialog';
import { withPermissions } from 'src/hocs/with-permissions';
import { VerificationStatuses } from 'view/dialogs/verifications/id-verification/verification-statuses';
import { complianceQueries } from 'features/compliance/data/compliance-query-model';
import VerificationTab from 'view/dialogs/verifications/id-verification/tabs/verification';
import { UseQuery } from 'src/lib/react-query';

const defaultStyles = StyleSheet({
  editDescription: {
    paddingBottom: '15px',
    fontSize: '14px'
  },
  verifiedBy: {
    position: 'relative',
    left: '-3px'
  },
  lockIcon: {
    color: COLORS.PRIMARY.SAND_LIGHT,
    width: '16px',
    height: '16px'
  }
});

const tabsOverride = {
  TabBar: StyleSheet({
    list: {
      padding: '20px 15px 0'
    }
  }),
  Tab: StyleSheet({
    container: {
      flex: 0
    }
  })
};

export const TYPES = {
  PERSON: 'person',
  COMPANY: 'company'
};

// The API sends and expects dates in the ISO8601 format: YYYY-MM-DD.
// However, the front end shows dates in DD/MM/YYYY format and accepts
// dates input in either DD/MM/YYYYY or DD-MM-YYYY format.

function fromApiDate(date) {
  return date ? dayjs(date).format('DD/MM/YYYY') : null;
}

function fromApiDocument(document) {
  return {
    ...document,
    expiry_date: fromApiDate(document.expiry_date),
    issue_date: fromApiDate(document.issue_date)
  };
}

function toApiDate(date) {
  return date
    ? dayjs(date.replace(/-/g, '/'), {
        format: 'DD/MM/YYYY'
      }).format('YYYY-MM-DD')
    : null;
}

function toApiDocument(document) {
  return {
    ...document,
    expiry_date: toApiDate(document.expiry_date),
    issue_date: toApiDate(document.issue_date),
    file_uri: document.file ? document.file.uri : null
  };
}

@withPermissions
@withErrorDialog
@withDialog(ConfirmationDialog, { propName: 'confirmation' })
@withDialog(NoticeDialog, { propName: 'notice' })
@withModel(identityVerificationsModel)
@withModel(adminIdentityRequirementsCompaniesModel)
@withModel(adminIdentityRequirementsPeopleModel)
@withModel(idVerificationRequirementCategoryPersonModel)
@withModel(idVerificationRequirementCategoryCompanyModel)
@withModel(sessionModel)
@styled(defaultStyles)
@autobind
class IDVerificationDialog extends PureComponent {
  static defaultProps = {
    id: null,
    type: TYPES.PERSON
  };

  constructor(props) {
    super(props);

    this.state = {
      verificationRecord: null,
      isVerified: false,
      canMarkAsVerified: false,
      loading: false,
      isSubmitting: false,
      formKey: 0
    };

    this.currentTypeModel =
      props.type === TYPES.PERSON
        ? idVerificationRequirementCategoryPersonModel
        : idVerificationRequirementCategoryCompanyModel;
    this.values = {};
    // HACK: To detect if we have fetched requirements or not
    this.hasFetchedRequirements = false;
  }

  componentDidMount() {
    const { contactIdentityVerifications, errorDialog, id, contactId } =
      this.props;

    if (!id && contactId) {
      contactIdentityVerifications
        .fetchList({
          id: `contact-id-${contactId}-verifications`,
          args: { criteria: getArgs(contactId) }
        })
        .then((response) => {
          const verificationRecord = _.get(response, 'data[0].item', {});
          const canMarkAsVerified = !!_.get(verificationRecord, 'id');
          const isVerified = !!_.get(verificationRecord, 'verified_at');

          this.setState({
            verificationRecord,
            canMarkAsVerified,
            isVerified
          });
        });
    }

    if (id) {
      // TODO: Reuse the search results that is done in the verification widget, if its available
      contactIdentityVerifications
        .fetchItem({ id })
        .then((response) => {
          const isVerified = !!_.get(response, 'data.verified_at');

          this.setState({
            verificationRecord: response.data,
            isVerified
          });
        })
        .catch(errorDialog.open);
    }
  }

  /* eslint-disable camelcase */
  hasSameAddresses({
    adr_locality,
    adr_postcode,
    adr_street_name,
    adr_street_number,
    adr_town,
    adr_unit_number,
    custom_address,
    registered_adr_locality,
    registered_adr_postcode,
    registered_adr_street_name,
    registered_adr_street_number,
    registered_adr_town,
    registered_adr_unit_number,
    registered_custom_address
  }) {
    return (
      adr_locality === registered_adr_locality &&
      adr_postcode === registered_adr_postcode &&
      adr_street_name === registered_adr_street_name &&
      adr_street_number === registered_adr_street_number &&
      adr_town === registered_adr_town &&
      adr_unit_number === registered_adr_unit_number &&
      custom_address === registered_custom_address
    );
  }

  getAddresses({
    adr_locality,
    adr_postcode,
    adr_street_name,
    adr_street_number,
    adr_town,
    adr_unit_number,
    custom_address,
    registered_adr_locality,
    registered_adr_postcode,
    registered_adr_street_name,
    registered_adr_street_number,
    registered_adr_town,
    registered_adr_unit_number,
    registered_custom_address,
    adr_same_as_registered,
    has_custom_address,
    registered_has_custom_address
  }) {
    // BE doesn't do the logic for us, so we have to do it on the FE
    // 1) make sure to use registered address if `adr_same_as_registered` is ticked
    // 2) make sure to use custom address if given (+ send null for all other fields)
    return adr_same_as_registered
      ? registered_has_custom_address
        ? {
            custom_address: registered_custom_address,
            registered_custom_address,
            adr_locality: null,
            adr_postcode: null,
            adr_street_name: null,
            adr_street_number: null,
            adr_town: null,
            adr_unit_number: null,
            registered_adr_locality: null,
            registered_adr_postcode: null,
            registered_adr_street_name: null,
            registered_adr_street_number: null,
            registered_adr_town: null,
            registered_adr_unit_number: null
          }
        : {
            adr_locality: registered_adr_locality,
            adr_postcode: registered_adr_postcode,
            adr_street_name: registered_adr_street_name,
            adr_street_number: registered_adr_street_number,
            adr_town: registered_adr_town,
            adr_unit_number: registered_adr_unit_number,
            registered_adr_locality,
            registered_adr_postcode,
            registered_adr_street_name,
            registered_adr_street_number,
            registered_adr_town,
            registered_adr_unit_number,
            custom_address: null,
            registered_custom_address: null
          }
      : {
          ...(has_custom_address
            ? {
                custom_address,
                adr_locality: null,
                adr_postcode: null,
                adr_street_name: null,
                adr_street_number: null,
                adr_town: null,
                adr_unit_number: null
              }
            : {
                adr_locality,
                adr_postcode,
                adr_street_name,
                adr_street_number,
                adr_town,
                adr_unit_number,
                custom_address: null
              }),
          ...(registered_has_custom_address
            ? {
                registered_custom_address,
                registered_adr_locality: null,
                registered_adr_postcode: null,
                registered_adr_street_name: null,
                registered_adr_street_number: null,
                registered_adr_town: null,
                registered_adr_unit_number: null
              }
            : {
                registered_adr_locality,
                registered_adr_postcode,
                registered_adr_street_name,
                registered_adr_street_number,
                registered_adr_town,
                registered_adr_unit_number,
                registered_custom_address: null
              })
        };
  }

  transformValues() {
    const { contactId, type } = this.props;
    const { verificationRecord } = this.state;
    const { identification, proofOfAddress, officeholders, ...rest } =
      this.values;

    const relatedDocuments = [...identification, ...proofOfAddress];

    const cleanData = _.omit(rest, [
      'id_verified_officeholder',
      'aml_completed_officeholder',
      'officeholders',
      'has_custom_address'
    ]);
    const verificationValues = {
      ...cleanData,
      ...this.getAddresses(this.values),
      contact_id: contactId,
      date_of_birth: toApiDate(this.values.date_of_birth),
      related: {
        contact_identity_verification_documents:
          relatedDocuments.map(toApiDocument),
        ...(type === TYPES.COMPANY
          ? { contact_identity_verification_contacts: officeholders }
          : {})
      }
    };

    return transformUpdateData({
      data: verificationValues,
      oldData: verificationRecord
    });
  }

  async handleSubmit() {
    const { errorDialog, contactIdentityVerifications, closeDialog, onClose } =
      this.props;
    const { verificationRecord, isVerified, willVerify } = this.state;

    const verificationId = _.get(verificationRecord, 'id', null);
    const payload = this.transformValues();
    const editMode = !!verificationId;

    // STEP 1: Update ID verification record or create a new one if we
    // don't have one yet
    let saveResponse;
    try {
      const saveAction = editMode
        ? contactIdentityVerifications.updateItem
        : contactIdentityVerifications.createItem;
      saveResponse = await saveAction({
        data: payload,
        id: verificationId
      });
    } catch (error) {
      errorDialog.open(error);
      return;
    }

    // STEP 2: Mark as verified if needed, assuming at this stage the
    // first step was always successful
    // NOTE: shape seems to be different between update and create?!?
    let returnData = editMode
      ? _.get(saveResponse, 'data')
      : _.get(saveResponse, 'data.result');
    const savedId = _.get(returnData, 'id');

    try {
      if (willVerify && savedId) {
        const verifyResponse =
          await contactIdentityVerifications.verifyIdentity({
            id: savedId,
            verified: isVerified,
            requirement_category_id: _.get(payload, 'requirement_category_id')
          });
        returnData = verifyResponse;
      }
    } catch (error) {
      // The marking failed, but the general update/create call succeeded, which means
      // the form in general got successfully submitted, so we want to reset the form
      // state and just reset the `isVerified` state, using `formKey` to reset form
      this.setState((state) => ({
        verificationRecord: _.get(saveResponse, 'data'),
        isVerified: false,
        canMarkAsVerified: true,
        formKey: state.formKey + 1
      }));
      errorDialog.open(error);
      return;
    }

    onClose(returnData);
    closeDialog();
  }
  /* eslint-enable camelcase */

  getInitialValues() {
    const { verificationRecord: data } = this.state;

    if (!data) {
      return {};
    }

    const requirementId = _.get(data, 'requirement_category.id');

    if (requirementId && !this.hasFetchedRequirements) {
      this.fetchRequirement({ target: { value: requirementId } });
      this.hasFetchedRequirements = true;
    }

    const officeholders = _.get(
      data,
      'related.contact_identity_verification_contacts',
      []
    );

    const hasIdVerifiedOfficeholder =
      getOfficeholdersIdVerification(officeholders);

    const hasAmlCompletedOfficeholder =
      getOfficeholdersAmlCompleted(officeholders);

    return {
      ...data,
      date_of_birth: fromApiDate(_.get(data, 'date_of_birth')),
      requirement_category_id: requirementId,
      adr_same_as_registered: this.hasSameAddresses(data),
      has_custom_address: !!data.custom_address,
      registered_has_custom_address: !!data.registered_custom_address,
      identification: _.get(
        data,
        'related.contact_identity_verification_documents',
        []
      )
        .filter((document) => _.get(document, 'proof_type.id') === 'identity')
        .map(fromApiDocument),
      proofOfAddress: _.get(
        data,
        'related.contact_identity_verification_documents',
        []
      )
        .filter((document) => _.get(document, 'proof_type.id') === 'address')
        .map(fromApiDocument),
      officeholders: officeholders,
      id_verified_officeholder: !!hasIdVerifiedOfficeholder || '',
      aml_completed_officeholder: !!hasAmlCompletedOfficeholder || ''
    };
  }

  renderDetailsContent() {
    const { requirementRecord, isVerified } = this.state;
    const { type } = this.props;

    return (
      <DetailsTab
        readOnly={isVerified}
        requirements={requirementRecord}
        values={this.values}
        setValues={this.setValues}
        type={type}
      />
    );
  }

  renderVerificationContent() {
    const { requirementRecord, isVerified } = this.state;
    const { type, contactId } = this.props;

    return (
      <PaddingBox>
        <UseQuery
          options={complianceQueries.search({
            recordId: contactId,
            service: 'Contacts'
          })}
        >
          {(query) => {
            return (
              <VerificationTab
                readOnly={isVerified}
                requirements={requirementRecord}
                values={this.values}
                setValues={this.setValues}
                type={type}
                recordId={contactId}
                complianceEntries={query.data?.rows}
              />
            );
          }}
        </UseQuery>
      </PaddingBox>
    );
  }

  async handleVerify() {
    const { contactIdentityVerifications, errorDialog, session } = this.props;
    const { verificationRecord } = this.state;
    const payload = this.transformValues();

    const hasContacts = !!_.get(
      payload,
      'related.contact_identity_verification_contacts'
    );
    const hasDocuments = !!_.get(
      payload,
      'related.contact_identity_verification_documents'
    );
    const hasRelated = !!_.get(payload, 'related');

    this.setState({ isVerifying: true });

    // NOTE: We are stripping out any related documents that are marked as destroyed
    await contactIdentityVerifications
      .checkFormValidation({
        id: _.get(verificationRecord, 'id'),
        data: {
          ...payload,
          ...(hasRelated
            ? {
                related: {
                  ...payload.related,
                  ...(hasContacts
                    ? {
                        contact_identity_verification_contacts:
                          payload.related.contact_identity_verification_contacts.filter(
                            (document) => !_.get(document, '_destroy')
                          )
                      }
                    : {}),
                  ...(hasDocuments
                    ? {
                        contact_identity_verification_documents:
                          payload.related.contact_identity_verification_documents.filter(
                            (document) => !_.get(document, '_destroy')
                          )
                      }
                    : {})
                }
              }
            : {})
        }
      })
      .then((response) => {
        const errors = _.get(response, 'data.result');

        if (errors.length) {
          errorDialog.open({ errors });
          return this.setState({ isVerifying: false });
        }

        this.setState({
          isVerifying: false,
          willVerify: true,
          isVerified: true,
          verifyDetails: {
            name: session?.user_details?.full_name,
            timestamp: dayjs().unix()
          }
        });
      })
      .catch((error) => {
        this.setState({ isVerifying: false });
        errorDialog.open(error);
      });
  }

  handleUndoVerify() {
    this.setState({
      willVerify: true,
      isVerified: false,
      canMarkAsVerified: true,
      verifyDetails: {}
    });
  }

  fetchRequirement(event) {
    const {
      adminIdentityRequirementsCompanies,
      adminIdentityRequirementsPeople,
      type
    } = this.props;

    this.setState({ loading: true });

    this.currentRequirementModel =
      type === TYPES.PERSON
        ? adminIdentityRequirementsPeople
        : adminIdentityRequirementsCompanies;

    this.currentRequirementModel
      .fetchItem({ id: event.target.value })
      .then(({ data }) => {
        this.setState(
          {
            requirementRecord: data,
            loading: false
          },
          () => {
            // HACK-NOTE: We do this so that the form re-runs validation
            // Otherwise the mark as verified button will not enable or disable itself
            this.setValues({ requirement_category_id: _.get(data, 'id') });
          }
        );
      });
  }

  handleValidation(values, extra) {
    // NOTE: This was disabled so users can see the Mark as Verified button after updating
    // the field values
    // if (_.get(extra, 'submitCount') < 1) {
    //   return {};
    // }

    const { type } = this.props;
    const { requirementRecord } = this.state;

    const rules = {
      requirement_category_id: ['required', `${type} type`]
    };

    if (!requirementRecord) {
      if (_.get(extra, 'submitCount') < 1) {
        return {};
      }
      return createValidationRules(rules)(values);
    }

    const validationMap = {
      tradingNameRequired: {
        wingsKey: 'trading_name_is_required',
        validation: [{ name: 'legal_name', humanReadableName: 'trading name' }]
      },
      companyRegistrationNumberIsRequired: {
        wingsKey: 'company_reg_number_is_required',
        validation: [{ name: 'company_number', humanReadableName: 'vat' }]
      },
      registeredOfficeRequired: {
        // wingsKey: 'registered_office_is_required',
        validation: [
          // NOTE: These are setup below as they are dynamic
        ],
        customCheck:
          _.get(requirementRecord, 'registered_office_is_required') ||
          (_.get(requirementRecord, 'main_place_of_business_is_required') &&
            !!_.get(values, 'adr_same_as_registered'))
      },
      mainPlaceOfBusinessRequired: {
        // wingsKey: 'main_place_of_business_is_required',
        validation: [
          // NOTE: Thesse are setup below as they are dynamic
        ],
        customCheck:
          _.get(requirementRecord, 'main_place_of_business_is_required') &&
          !_.get(values, 'adr_same_as_registered')
      },
      officeholdersRequired: {
        wingsKey: 'office_holders_required',
        validation: [
          { name: 'officeholders', humanReadableName: 'office holders' }
        ]
      },
      officeholdersMustHaveIdVerified: {
        wingsKey: 'office_holders_must_have_id_verified',
        validation: [
          {
            name: 'id_verified_officeholder',
            humanReadableName: 'id verified office holder'
          }
        ]
      },
      officeholdersMustHaveAmlCheckCompleted: {
        wingsKey: 'office_holders_must_have_aml_check_completed',
        validation: [
          {
            name: 'aml_completed_officeholder',
            humanReadableName: 'aml completed office holder'
          }
        ]
      }
    };

    const registeredAddressHasCustomAddress = _.get(
      values,
      'registered_has_custom_address'
    );

    const mainPlaceOfBusinessHasCustomAddress = _.get(
      values,
      'has_custom_address'
    );

    validationMap.registeredOfficeRequired.validation =
      registeredAddressHasCustomAddress
        ? [
            {
              name: 'registered_custom_address',
              humanReadableName: 'registered address'
            }
          ]
        : [
            // {
            //   name: 'registered_adr_unit_number',
            //   humanReadableName: 'registered unit number'
            // },
            {
              name: 'registered_adr_street_number',
              humanReadableName: 'registered street number'
            },
            {
              name: 'registered_adr_street_name',
              humanReadableName: 'registered street name'
            },
            // {
            //   name: 'registered_adr_locality',
            //   humanReadableName: 'registered locality'
            // },
            {
              name: 'registered_adr_town',
              humanReadableName: 'registered town'
            },
            {
              name: 'registered_adr_postcode',
              humanReadableName: 'registered postcode'
            }
          ];

    validationMap.mainPlaceOfBusinessRequired.validation =
      mainPlaceOfBusinessHasCustomAddress
        ? [
            {
              name: 'custom_address',
              humanReadableName: 'main place of business address'
            }
          ]
        : [
            // {
            //   name: 'adr_unit_number',
            //   humanReadableName: 'main place of business unit number'
            // },
            {
              name: 'adr_street_number',
              humanReadableName: 'main place of business street number'
            },
            {
              name: 'adr_street_name',
              humanReadableName: 'main place of business street name'
            },
            // {
            //   name: 'adr_locality',
            //   humanReadableName: 'main place of business locality'
            // },
            {
              name: 'adr_town',
              humanReadableName: 'main place of business town'
            },
            {
              name: 'adr_postcode',
              humanReadableName: 'main place of business postcode'
            }
          ];

    // TODO: Need to check which address type the user is using
    // E.g. text area or individual inputs
    // Then we have to make sure the validation runs on the correct field names

    // Setup map item's value key
    Object.keys(validationMap).forEach((camelCaseKey) => {
      validationMap[camelCaseKey].value =
        _.get(requirementRecord, validationMap[camelCaseKey].wingsKey) ||
        _.get(validationMap, `${camelCaseKey}.customCheck`);
    });

    // Setup rules
    Object.keys(validationMap).forEach((camelCaseKey) => {
      if (validationMap[camelCaseKey].value) {
        validationMap[camelCaseKey].validation.forEach((validator) => {
          rules[validator.name] = ['required', validator.humanReadableName];
        });
      }
    });

    const validationResults = createValidationRules(rules)(values);

    this.setState({
      canMarkAsVerified: !Object.keys(validationResults).length
    });

    return {};
  }

  handleCancel() {
    const { confirmation, closeDialog } = this.props;
    const { willVerify } = this.state;
    if (willVerify) {
      return confirmation.open({
        title: 'ID Verification',
        message:
          'You have changed the ID verification status. Do you want to save this change?',
        cancelText: 'Discard change',
        confirmText: 'Save',
        onConfirm: this.submitForm,
        onCancel: closeDialog
      });
    }

    closeDialog();
  }

  render() {
    const { styles: s, onLoad, id, type, contactId } = this.props;
    const {
      verificationRecord,
      isVerified,
      canMarkAsVerified,
      isVerifying,
      verifyDetails,
      willVerify,
      formKey
    } = this.state;
    const title = 'ID Verification';
    const isLoading = (!!id || !!contactId) && verificationRecord === null;

    return (
      <Dialog
        title={title}
        width={650}
        height={1100}
        closeDialog={this.handleCancel}
        onLoad={onLoad}
        isLoading={isLoading}
        hasPadding={false}
        showLoadingSpinner
      >
        <PaddingBox pb={'0px'}>
          <VerificationStatuses
            recordId={contactId}
            service='Contacts'
            verificationRecord={verificationRecord}
            verificationDetails={verifyDetails}
            onUndoVerify={this.handleUndoVerify}
            isVerifying={isVerifying}
            isVerified={isVerified}
            canMarkAsVerified={canMarkAsVerified}
            onVerify={this.handleVerify}
            contactType={type}
          />
        </PaddingBox>

        <ReactForms
          key={formKey}
          validateOnMount
          handleSubmit={this.handleSubmit}
          initialValues={this.getInitialValues()}
          validate={this.handleValidation}
        >
          {({ values, setValues, submitForm, isSubmitting }) => {
            this.values = values;
            this.setValues = setValues;
            this.submitForm = submitForm;

            return (
              <Form hasErrorPadding>
                <PaddingBox pt={PADDINGS.S} pb={'0px'}>
                  <FormField
                    name='requirement_category_id'
                    label={`${type === TYPES.PERSON ? 'contact' : type} type*`}
                    Input={ValueListSelect}
                    onChange={this.fetchRequirement}
                    inputProps={{
                      models: [this.currentTypeModel],
                      disabled: isVerified
                    }}
                  />
                </PaddingBox>

                {/* Used for the Verification Tab values */}
                <HiddenField name='identification' sendImmediate />
                <HiddenField name='proofOfAddress' sendImmediate />
                <HiddenField
                  name='officeholders'
                  // HACK: There is a bug with forms where changing more than one field at a time
                  // triggers validation for each value changed, but in each instance of validation,
                  // it doesn't see the other values that have changed. So the validation is incorrect, as
                  // it will likely tell you fields need to be filled out, but they are already complete
                  // https://app.clubhouse.io/rexlabs/story/54182/investigate-setvalues-in-form-to-see-why-fields-are-set-separately
                  onChange={() => {
                    this.handleValidation(this.values);
                  }}
                  value={values.officeholders}
                  sendImmediate
                />
                <HiddenField name='id_verified_officeholder' sendImmediate />
                <HiddenField name='aml_completed_officeholder' sendImmediate />

                <StylesProvider
                  components={tabsOverride}
                  prioritiseParentStyles={false}
                >
                  <TabsStateful
                    alwaysRenderAll
                    nested
                    items={[
                      {
                        name: 'details',
                        label: 'Details',
                        Tab: this.renderDetailsContent
                      },
                      {
                        name: 'verification',
                        label: 'Verification',
                        Tab: this.renderVerificationContent
                      }
                    ]}
                  />
                </StylesProvider>

                <PaddingBox>
                  <SaveCancelButtonBar
                    onCancel={this.handleCancel}
                    onSave={submitForm}
                    isLoading={isSubmitting || isVerifying}
                    hasPadding={false}
                    isDisabled={isVerified && !willVerify}
                  />
                </PaddingBox>
              </Form>
            );
          }}
        </ReactForms>
      </Dialog>
    );
  }
}

export default IDVerificationDialog;
