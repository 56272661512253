/* eslint-disable max-lines */
// Vendor
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import _ from 'lodash';

// Rexlabs
import Box from '@rexlabs/box';
import { styled, StyleSheet } from '@rexlabs/styling';
import { withModel } from '@rexlabs/model-generator';

// Local
import { COLORS, PADDINGS } from 'theme';
import List, {
  RexBasicCell,
  RexHeadingCell,
  RexPrimaryTextCell
} from 'view/components/list';
import { IconButton } from 'view/components/button';
import { Link } from 'components/text/link';
import { Hint } from 'components/text/hint';
import { DialogBody } from 'components/text/dialog-body';
import { DialogSectionHeading } from 'components/text/dialog-section-heading';
import AddIdentification from 'view/dialogs/verifications/id-verification/add-identification';
import AddRelatedContactDialog from 'view/dialogs/add-related-contact';
import { TYPES as ID_VERIFICATION_TYPES } from 'view/dialogs/verifications/id-verification';
import { ContactPreviewPopout } from 'features/contacts/popouts/contact-preview-popout';
import DialogsBridge from 'data/classic-bridges/dialogs-shell';
import { getArgs } from 'data/queries/id-verifications';
import amlChecksModel from 'data/models/entities/contact-aml-checks';
import identityVerificationsModel from 'data/models/entities/contact-identity-verifications';
import {
  getOfficeholdersAmlCompleted,
  getOfficeholdersIdVerification
} from 'view/dialogs/verifications/id-verification/utils'; // Shared
import { ICONS } from 'shared/components/icon';
import Spinner from 'shared/components/spinner';
import { withDialog } from 'shared/hocs/with-dialog';
import { withPermissions } from 'src/hocs/with-permissions';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';

const AddIcon = (props) => <ICONS.ADD {...props} style={{ width: '13px' }} />;

/**
 * Handles removing a single record from an array of records. This is required as some freshly
 * created records won't have a proper ID assigned to them. The IDs are created by Wings so we have
 * to do some extra checks when they are missing this ID.
 *
 * It handles all types of records seen in the Verification tab.
 * - Identification
 * - Proof of Address
 * - Officeholders
 *
 * @param {Array} records Array of records that should have a record removed from it
 * @param {Object} removedRecord The record to be removed from the array of records
 * @param {Object} replacementRecord The record that should replace the removed record
 */
const removeRecordSafely = (records, removedRecord, replacementRecord) => {
  const haveOfficeholder = !!_.get(removedRecord, 'contact.id');

  const removedRecordId = _.get(removedRecord, 'id')
    ? _.get(removedRecord, 'id')
    : haveOfficeholder
    ? _.get(removedRecord, 'contact.id')
    : `${_.get(removedRecord, 'reference')}${_.get(removedRecord, 'type.id')}`;

  return records
    .map((record) => {
      const recordId = _.get(record, 'id')
        ? _.get(record, 'id')
        : haveOfficeholder
        ? _.get(record, 'contact.id')
        : `${_.get(record, 'reference')}${_.get(record, 'type.id')}`;
      return recordId === removedRecordId ? replacementRecord : record;
    })
    .filter(Boolean);
};

// @withDialog(AMLDialog, { propName: 'amlCheck' })
// @withDialog(IDVerificationDialog, { propName: 'idVerification' })
@withPermissions
@withDialog(AddIdentification, { propName: 'addIdentification' })
@withDialog(AddRelatedContactDialog, { propName: 'addOfficeholder' })
@withModel(amlChecksModel)
@withModel(identityVerificationsModel)
@autobind
class VerificationContent extends Component {
  static defaultProps = {
    readOnly: false
  };

  constructor(props) {
    super(props);

    this.identificationHeadings = [];
    this.proofOfAddressHeadings = [];
    this.officeholderHeadings = [];
  }

  componentDidMount() {
    this.identificationHeadings = this.getIdentificationHeadings();
    this.proofOfAddressHeadings = this.getProofOfAddressHeadings();
    this.officeholderHeadings = this.getOfficeholderHeadings();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.readOnly !== this.props.readOnly) {
      this.identificationHeadings = this.getIdentificationHeadings();
      this.proofOfAddressHeadings = this.getProofOfAddressHeadings();
      this.officeholderHeadings = this.getOfficeholderHeadings();
    }
  }

  getIdentificationHeadings() {
    return [
      {
        label: 'Type',
        id: 'type.name',
        actions: this.props.readOnly
          ? null
          : [
              {
                label: 'Edit',
                onClick: (event, record) => {
                  const {
                    setValues,
                    values: { identification },
                    addIdentification
                  } = this.props;

                  addIdentification.open({
                    type: 'identity',
                    documentRecord: record,
                    onClose: ({ data }) => {
                      const withReplacementRecord = removeRecordSafely(
                        identification,
                        record,
                        data
                      );
                      setValues({
                        identification: withReplacementRecord
                      });
                    }
                  });
                }
              },
              {
                label: 'Copy to Proof of Address',
                onClick: (event, record) => {
                  const {
                    setValues,
                    values: { proofOfAddress }
                  } = this.props;

                  const newProofOfAddress = {
                    ...record,
                    id: null,
                    proof_type: {
                      id: 'address'
                    }
                  };

                  setValues({
                    proofOfAddress: [...proofOfAddress, newProofOfAddress]
                  });
                },
                visible: (requirements) => {
                  return !_.get(
                    requirements,
                    'proof_of_address_cannot_reuse_id_type'
                  );
                }
              },
              {
                label: 'Remove',
                onClick: (event, record) => {
                  const {
                    setValues,
                    values: { identification }
                  } = this.props;

                  const withoutRecord = removeRecordSafely(
                    identification,
                    record
                  );
                  setValues({
                    identification: withoutRecord
                  });
                }
              }
            ]
      },
      {
        label: 'Category',
        id: 'type.category.text'
      },
      {
        label: 'Reference',
        id: 'reference'
      },
      ...(hasFeatureFlags('compliance')
        ? [
            {
              label: 'Rex PM Status',
              id: 'compliance_verification.verification_status.text'
            }
          ]
        : [])
    ];
  }

  getProofOfAddressHeadings() {
    return [
      {
        label: 'Type',
        id: 'type.name',
        actions: this.props.readOnly
          ? null
          : [
              {
                label: 'Edit',
                onClick: (event, record) => {
                  const {
                    setValues,
                    values: { proofOfAddress },
                    addIdentification
                  } = this.props;

                  addIdentification.open({
                    type: 'address',
                    documentRecord: record,
                    onClose: ({ data }) => {
                      const withReplacementRecord = removeRecordSafely(
                        proofOfAddress,
                        record,
                        data
                      );
                      setValues({
                        proofOfAddress: withReplacementRecord
                      });
                    }
                  });
                }
              },
              {
                label: 'Copy to Identification',
                onClick: (event, record) => {
                  const {
                    setValues,
                    values: { identification }
                  } = this.props;

                  const newIdentification = {
                    ...record,
                    id: null,
                    proof_type: {
                      id: 'identity'
                    }
                  };

                  setValues({
                    identification: [...identification, newIdentification]
                  });
                },
                visible: (requirements) => {
                  return !_.get(
                    requirements,
                    'proof_of_address_cannot_reuse_id_type'
                  );
                }
              },
              {
                label: 'Remove',
                onClick: (event, record) => {
                  const {
                    setValues,
                    values: { proofOfAddress }
                  } = this.props;

                  const withoutRecord = removeRecordSafely(
                    proofOfAddress,
                    record
                  );
                  setValues({
                    proofOfAddress: withoutRecord
                  });
                }
              }
            ]
      },
      {
        label: 'Category',
        id: 'type.category.text'
      },
      {
        label: 'Reference',
        id: 'reference'
      },
      ...(hasFeatureFlags('compliance')
        ? [
            {
              label: 'Rex PM Status',
              id: 'compliance_verification.verification_status.text'
            }
          ]
        : [])
    ];
  }

  getOfficeholderHeadings() {
    return [
      {
        label: 'Contact',
        id: 'contact.name',
        Popout: ({ data, children }) => {
          return (
            <ContactPreviewPopout id={_.get(data, 'contact.id')}>
              <Link record blue>
                {children}
              </Link>
            </ContactPreviewPopout>
          );
        },
        actions: this.props.readOnly
          ? null
          : [
              {
                label: 'View ID verification',
                onClick: (event, record) => {
                  DialogsBridge.idVerification.open({
                    contactId: _.get(record, 'contact.id'),
                    type: _.get(record, 'type', ID_VERIFICATION_TYPES.PERSON),
                    onClose: ({ data }) => {
                      const {
                        setValues,
                        values: { officeholders }
                      } = this.props;

                      const isVerified = !!_.get(data, 'result.verified_at');

                      const updatedData = {
                        ...record,
                        id_verified: isVerified
                      };

                      const withReplacementRecord = removeRecordSafely(
                        officeholders,
                        record,
                        updatedData
                      );
                      const hasIdVerifiedOfficeholders =
                        getOfficeholdersIdVerification(withReplacementRecord);

                      setValues({
                        officeholders: withReplacementRecord,
                        id_verified_officeholder:
                          hasIdVerifiedOfficeholders || ''
                      });
                    }
                  });
                }
              },
              {
                label: 'View AML check',
                onClick: (event, record) => {
                  DialogsBridge.amlCheck.open({
                    contact: record.contact,
                    callback: (data) => {
                      const {
                        setValues,
                        values: { officeholders }
                      } = this.props;

                      const updatedData = {
                        ...record,
                        aml_check_status: _.get(data, 'status', {
                          text: 'Incomplete'
                        })
                      };

                      const withReplacementRecord = removeRecordSafely(
                        officeholders,
                        record,
                        updatedData
                      );

                      const hasAmlCompletedOfficeholders =
                        getOfficeholdersAmlCompleted(withReplacementRecord);

                      setValues({
                        officeholders: withReplacementRecord,
                        aml_completed_officeholder:
                          hasAmlCompletedOfficeholders || ''
                      });
                    }
                  });
                }
              },
              {
                label: 'Remove',
                onClick: (event, record) => {
                  const {
                    setValues,
                    values: { officeholders }
                  } = this.props;

                  const withoutRecord = removeRecordSafely(
                    officeholders,
                    record
                  );
                  setValues({
                    officeholders: withoutRecord
                  });
                }
              }
            ]
      },
      {
        label: 'Role',
        id: 'relationship_type.text'
      },
      {
        label: 'ID verification',
        id: 'id_verified',
        /**
         * This is used to map a value from one to another. In the example below Wings is returning
         * a boolean but we want to show the user some text, instead.
         */
        valueMap: {
          true: 'Verified',
          false: 'Unverified'
        }
      },
      {
        label: 'AML check',
        id: 'aml_check_status.text'
      }
    ];
  }

  handleAddIdentification() {
    const {
      setValues,
      values: { identification }
    } = this.props;
    this.props.addIdentification.open({
      type: 'identity',
      onClose: ({ data }) => {
        setValues({
          identification: [...identification, data]
        });
      }
    });
  }

  handleAddProofOfAddress() {
    const {
      setValues,
      values: { proofOfAddress }
    } = this.props;
    this.props.addIdentification.open({
      type: 'address',
      onClose: ({ data }) => {
        setValues({
          proofOfAddress: [...proofOfAddress, data]
        });
      }
    });
  }

  handleAddOfficeholders() {
    const {
      addOfficeholder,
      recordId,
      setValues,
      values: { officeholders },
      contactIdentityVerifications,
      contactAntiMoneyLaunderingChecks
    } = this.props;

    addOfficeholder.open({
      onSave: async (values) => {
        const contact = _.get(values, 'contact.data');
        /**
         * NOTE: When a new contact is created using a fixture we don't have access to the name
         * it's only stored in the system_search_key for some reason 🤷‍ so that's why
         * we use the label, as it is always set to the correct value.
         */
        const contactName = _.get(values, 'contact.label');

        const identityVerification =
          await contactIdentityVerifications.fetchList({
            id: `contact-id-${contact.id}-verifications`,
            args: { criteria: getArgs(contact.id) }
          });

        const verificationRecord = _.get(identityVerification, 'data.0.item');

        const isVerified = !!_.get(verificationRecord, 'verified_at');

        const amlCheck = await contactAntiMoneyLaunderingChecks.fetchList({
          id: `contact-id-${contact.id}-aml-checks`,
          args: { criteria: getArgs(contact.id) }
        });
        const amlCheckStatus = _.get(amlCheck, 'data.0.item.status');

        const withNewOfficeholders = [
          ...officeholders,
          {
            contact: {
              ...contact,
              name: contactName
            },
            relationship_type: {
              id: _.get(values, 'role.value'),
              text: _.get(values, 'role.label')
            },
            id_verified: isVerified,
            aml_check_status: amlCheckStatus
          }
        ];

        const hasIdVerifiedOfficeholders =
          getOfficeholdersIdVerification(withNewOfficeholders);
        const hasAmlCompletedOfficeholders =
          getOfficeholdersAmlCompleted(withNewOfficeholders);

        setValues({
          officeholders: withNewOfficeholders,
          id_verified_officeholder: !!hasIdVerifiedOfficeholders || '',
          aml_completed_officeholder: !!hasAmlCompletedOfficeholders || ''
        });
      },
      baseRecordId: recordId
    });
  }

  getIdentificationItems() {
    const { values, complianceEntries } = this.props;
    return values?.identification?.map((identification) => {
      return {
        ...identification,
        compliance_verification: complianceEntries?.filter(
          (c) => c.type_id === 'contact_id_check'
        )[0]?.verification || {
          verification_status: { id: 'needs', text: 'needs verification ' }
        }
      };
    });
  }

  getProofOfAddressItems() {
    const { values, complianceEntries } = this.props;
    return values?.proofOfAddress?.map((proofOfAddress) => {
      return {
        ...proofOfAddress,
        compliance_verification: complianceEntries?.filter(
          (c) => c.type_id === 'contact_proof_of_address'
        )[0]?.verification || {
          verification_status: { id: 'needs', text: 'needs verification ' }
        }
      };
    });
  }

  render() {
    const isCompany = this.props.type === 'company';
    const { values, requirements, readOnly, recordId } = this.props;
    const identificationItems = this.getIdentificationItems();
    const proofOfAddressItems = this.getProofOfAddressItems();
    const officeholderItems = _.get(values, 'officeholders');
    const requirementsDescription = _.get(
      requirements,
      'requirements_preview',
      ''
    );
    const identificationRequired = !!requirementsDescription.length;
    const proofOfAddressRequired = _.get(
      requirements,
      'proof_of_address_is_required'
    );
    const proofOfAddressDescription = proofOfAddressRequired
      ? 'At least one proof of address document is required'
      : '';
    const officeholderRequired = _.get(requirements, 'office_holders_required');
    return (
      <Box>
        <Box>
          <Box flex={1} alignItems='center'>
            <DialogSectionHeading>
              Identification{identificationRequired && '*'}
            </DialogSectionHeading>
            <IconButton
              isDisabled={readOnly}
              red
              circle
              Icon={AddIcon}
              onClick={this.handleAddIdentification}
            >
              add identification
            </IconButton>
          </Box>
          <Box pb={PADDINGS.S}>
            <DialogBody italic light>
              {requirementsDescription}
            </DialogBody>
          </Box>
          <Box pb={PADDINGS.M}>
            <BasicList
              name='identification'
              headings={this.identificationHeadings}
              items={identificationItems}
              requirements={requirements}
            />
          </Box>
        </Box>
        <Box>
          <Box flex={1} alignItems='center'>
            <DialogSectionHeading>
              Proof of Address{proofOfAddressRequired && '*'}
            </DialogSectionHeading>
            <IconButton
              isDisabled={readOnly}
              red
              circle
              Icon={AddIcon}
              onClick={this.handleAddProofOfAddress}
            >
              add proof of address
            </IconButton>
          </Box>
          <Box pb={PADDINGS.S}>
            <DialogBody italic light>
              {proofOfAddressDescription}
            </DialogBody>
          </Box>
          <Box pb={PADDINGS.M}>
            <BasicList
              name='proof of address'
              headings={this.proofOfAddressHeadings}
              items={proofOfAddressItems}
              requirements={requirements}
            />
          </Box>
        </Box>
        {isCompany && (
          <Box>
            <Box flex={1} alignItems='center'>
              <DialogSectionHeading>
                Officeholders{officeholderRequired && '*'}
              </DialogSectionHeading>
              <IconButton
                isDisabled={readOnly}
                red
                circle
                Icon={AddIcon}
                onClick={this.handleAddOfficeholders}
              >
                add officeholders
              </IconButton>
            </Box>
            <Box pb={PADDINGS.S}>
              {/* <DialogBody italic light>
                2 types from category Company
              </DialogBody> */}
            </Box>
            <Box pb={PADDINGS.M}>
              <BasicList
                name='officeholders'
                headings={this.officeholderHeadings}
                items={officeholderItems}
                requirements={requirements}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  }
}

const defaultStyles = StyleSheet({
  emptyContainer: {
    backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT,
    minHeight: '112px',
    width: '100%'
  },

  cellContainer: {
    flex: 1,
    flexShrink: 0,
    width: '33.3%'
  }
});

@styled(defaultStyles)
@autobind
class BasicList extends Component {
  static defaultProps = {
    isLoading: false,
    requirements: {}
  };

  renderHeader() {
    const { headings } = this.props;
    return (
      <Box flexDirection='row' flex={1}>
        {headings.map((heading) => {
          const { label, id } = heading;
          return (
            <RexHeadingCell key={id} flex={1}>
              {label}
            </RexHeadingCell>
          );
        })}
      </Box>
    );
  }

  renderItem(item) {
    const { styles: s, headings, requirements } = this.props;

    return (
      <Box flexDirection='row' flex={1} key={item.id}>
        <Box flexDirection='row' flex={1} width='100%'>
          {headings.map((heading) => {
            const { id, valueMap, actions, Popout } = heading;
            const Cell = actions ? RexPrimaryTextCell : RexBasicCell;
            const itemContent = _.get(item, `${id}`);
            const content = valueMap
              ? _.get(valueMap, _.get(item, `${id}`))
              : itemContent;

            // NOTE: We can probably move this out to an abstraction of some sort
            // we shouldn't have to do it per list ideally.
            const actionsWithItem = actions
              ? actions
                  .map((action) => {
                    if (action.visible && !action.visible(requirements)) {
                      return undefined;
                    }

                    const newAction = {
                      ...action
                    };

                    // Add the item into the list of arguments that the onClick is called with
                    // by default
                    const originalClickHandler = newAction.onClick;
                    newAction.onClick = (...args) => {
                      return originalClickHandler.apply(this, [
                        ...args,
                        { ...item }
                      ]);
                    };

                    return newAction;
                  })
                  .filter(Boolean)
              : null;

            return (
              <div {...s('cellContainer')} key={id}>
                <Cell ellipsis actionMenuItems={actionsWithItem}>
                  {Popout ? (
                    Popout({
                      data: item,
                      children: <span {...s('label')}>{content}</span>
                    })
                  ) : (
                    <span {...s('label')}>{content}</span>
                  )}
                </Cell>
              </div>
            );
          })}
        </Box>
      </Box>
    );
  }

  renderEmptyState() {
    const { styles: s, isLoading, name } = this.props;
    return (
      <Box
        {...s('emptyContainer')}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {isLoading ? (
          <Spinner small dark />
        ) : (
          <Hint semibold>No {name} found.</Hint>
        )}
      </Box>
    );
  }

  getListStyles = _.memoize(
    (headings) =>
      StyleSheet({
        separator: {
          display: 'none'
        },

        wrapItem: {
          '&:nth-child(odd)': {
            backgroundColor: COLORS.BACKGROUNDS.SAND_LIGHT
          },
          ' > div > div': {
            maxWidth: '100%',
            ' > div': {
              maxWidth: `${100 / headings.length}%`
            }
          }
        }
      }),
    (headings) => headings.map((heading) => heading.label).toString()
  );

  render() {
    const { headings, ...props } = this.props;
    return (
      <List
        styles={this.getListStyles(headings)}
        Header={this.renderHeader}
        EmptyView={this.renderEmptyState}
        renderItem={this.renderItem}
        {...props}
      />
    );
  }
}

export default VerificationContent;
