import { useQuery } from '@tanstack/react-query';
import {
  ComplianceEntry,
  complianceQueries
} from 'features/compliance/data/compliance-query-model';
import Icon, { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import React, { useMemo } from 'react';
import { COLORS as SHARED_COLORS, COLORS } from 'shared/theme/index';

type VerificationComplianceStatusProps = {
  recordId: string;
  service: string;
  typeId: string;
  label: string;
  size?: 'md' | 'lg';
};

export function VerificationComplianceStatus({
  recordId,
  service,
  typeId,
  label,
  size = 'md'
}: VerificationComplianceStatusProps) {
  const { data: complianceEntries } = useQuery(
    complianceQueries.search({
      recordId,
      service,
      typeId
    })
  );

  const isFailedStatus = (
    verification: ComplianceEntry['verification'] | null
  ) => {
    if (!verification) return null;
    return (
      verification.verification_status.id === 'rejected' ||
      verification.verification_status.id === 'expired'
    );
  };

  const verification = useMemo(() => {
    if (!complianceEntries) return null;
    return complianceEntries.rows.filter((c) => c.type_id === typeId)[0]
      ?.verification;
  }, [complianceEntries, typeId]);

  return (
    <Box flexDirection={'row'} alignItems={'center'} style={{ gap: '5px' }}>
      <Icon
        type={isFailedStatus(verification) ? ICONS.WARNING : ICONS.PADLOCK}
        width={size === 'lg' ? 22 : 16}
        height={size === 'lg' ? 22 : 16}
        color={
          isFailedStatus(verification)
            ? SHARED_COLORS.STATUS.RED
            : COLORS.SAND_DARK
        }
      />
      {verification ? (
        <Body regular normal dark>
          {label} {verification?.verification_status?.text} in{' '}
          {verification?.verification_source?.text}
          {verification?.verified_by_name
            ? ` by ${verification?.verified_by_name} on `
            : ' on '}
          {verification?.verified_date}
        </Body>
      ) : (
        <Body regular normal dark>
          {label} needs verification in Rex PM
        </Body>
      )}
    </Box>
  );
}
