import React from 'react';
import { VerificationComplianceStatus } from './verification-compliance-status';
import Box from '@rexlabs/box';

export function VerificationIdCheckComplianceStatuses({
  recordId,
  service
}: {
  recordId: string;
  service: string;
}) {
  return (
    <Box flexDirection={'column'} style={{ gap: '10px' }} marginTop={'20px'}>
      <VerificationComplianceStatus
        recordId={recordId}
        service={service}
        typeId='contact_id_check'
        label='Identification'
      />
      <VerificationComplianceStatus
        recordId={recordId}
        service={service}
        typeId='contact_proof_of_address'
        label='Proof of Address'
      />
    </Box>
  );
}
