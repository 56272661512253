import Icon, { ICONS } from 'shared/components/icon';
import Box from '@rexlabs/box';
import { Body } from 'components/text/body';
import { SubText } from 'components/text/sub-text';
import React from 'react';
import { DefaultButton } from 'view/components/button';
import { InfoTooltip } from 'view/components/tooltip';
import Spinner from 'shared/components/spinner';
import { PADDINGS } from 'src/theme';
import { hasFeatureFlags } from 'shared/utils/has-feature-flags';
import { COLORS } from 'shared/theme/index';
import { VerificationIdCheckComplianceStatuses } from './verification-id-check-compliance-statuses';

export function VerificationStatuses({
  recordId,
  service,
  verificationRecord,
  verificationDetails,
  onUndoVerify,
  isVerifying,
  canMarkAsVerified,
  isVerified,
  onVerify,
  contactType
}: {
  recordId: string;
  service: string;
  verificationRecord: any;
  verificationDetails: any;
  onUndoVerify: () => void;
  isVerifying: boolean;
  canMarkAsVerified: boolean;
  isVerified: boolean;
  onVerify: () => void;
  contactType: string;
}) {
  const verifyingUsersName =
    verificationRecord?.verified_by_user?.name ||
    verificationDetails?.name ||
    'deactivated user';

  const verifiedOn = isVerified
    ? new Date(
        (verificationDetails?.timestamp
          ? verificationDetails?.timestamp
          : verificationRecord?.verified_at) * 1000
      ).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    : null;

  return (
    <Box>
      {!isVerified && (
        <Box pl={0} pr={0} style={{ gap: '10px' }} flexDirection={'column'}>
          <Body semibold normal dark>
            ID Verification Status
          </Body>
          <Body regular normal dark style={{ maxWidth: '400px' }}>
            Fill out the ID information before verifying ID. All fields marked
            with an asterisk * are required to verify.
            <InfoTooltip
              inline
              small
              content={`Select a ${contactType} type to view the requirements`}
            />
          </Body>

          {isVerifying ? (
            <Box ml={PADDINGS.S} mr={PADDINGS.S}>
              <Spinner dark small />
            </Box>
          ) : (
            <DefaultButton
              dark
              isDisabled={!canMarkAsVerified}
              IconLeft={ICONS.PADLOCK}
              onClick={onVerify}
              style={{ alignSelf: 'flex-start' }}
            >
              Mark as Verified
            </DefaultButton>
          )}
        </Box>
      )}
      {isVerified && (
        <Box flexDirection={'row'} style={{ gap: '5px' }}>
          <Icon
            type={ICONS.PADLOCK}
            width={16}
            height={16}
            color={COLORS.SAND_DARK}
          />
          <Body regular normal dark>
            Overall status verified by {verifyingUsersName} {verifiedOn}
          </Body>
          <SubText linked onClick={onUndoVerify}>
            undo
          </SubText>
        </Box>
      )}

      {hasFeatureFlags('compliance') && (
        <VerificationIdCheckComplianceStatuses
          recordId={recordId}
          service={service}
        />
      )}
    </Box>
  );
}
