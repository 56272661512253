/* eslint-disable max-lines */
import { some, startCase, startsWith } from 'lodash';

/**
 * Take a route config object with the shape
 *
 * {
 *   [regex]: {
 *     title: 'Listings'
 *     sub: {
 *       [regex]: {
 *          title: 'Record',
 *          sub: {
 *            [regex]: {
 *              title: 'VPA',
 *              ...etc
 *            }
 *          }
 *       }
 *     }
 *   }
 * }
 *
 * and recursively construct a route title
 * composed of the 'title' of the base as well
 * as the titles of the sub routes that also
 * match the URL path + hash.
 *
 * It will match everything under the sub key
 * as long as the pattern matches so there is
 * a 'stop' property to bail early
 */
export function constructRouteTitle({ path, hash, mapping }) {
  let routeTitle = '';

  // If there is a viewpath in the hash of the URL use that
  // as the path instead so the route title works with the stepthrough
  let pathWithHash = path;
  try {
    pathWithHash =
      hash && startsWith(hash.toLowerCase(), 'viewpath')
        ? decodeURIComponent(hash.substr(9, hash.length))
        : decodeURIComponent(`${path}${hash ? `#${hash}` : ''}`);
  } catch (e) {
    console.error('URL decoding failed', e);
  }

  some(mapping, ({ title, getTitle, sub, stop }, key) => {
    if (pathWithHash.match(key)) {
      const customTitle = getTitle ? getTitle({ path, pathWithHash }) : title;
      routeTitle = routeTitle ? `${routeTitle} - ${customTitle}` : customTitle;

      if (sub) {
        const subPath = constructRouteTitle({
          path,
          hash,
          mapping: sub
        });

        routeTitle = `${routeTitle}${subPath ? ` - ${subPath}` : ''}`;
      }

      if (stop) return true;
    }
  });

  return routeTitle;
}

export const routeMapping = {
  '^/?(?:(?:home|#)(:?/?.*)?)?$': {
    title: 'Home',
    sub: {
      'view=reminders': { title: 'Reminders' },
      'view=Holds': { title: 'Holds' },
      'view=Feedback': { title: 'Feedback' },
      'view=Expiring+Listings': { title: 'Expiring Listings' }
    }
  },
  '^/boards/?': { title: 'Dashboards' },
  '^/calendar/?': { title: 'Calendar' },
  '^/listings/?': {
    title: 'Listings',
    sub: {
      'id=.*': {
        title: 'Record',
        sub: {
          'financialTab=invoicing': {
            title: 'Invoices & Payments',
            stop: true
          },
          'financialTab=billing': {
            title: 'Bills & Payments',
            stop: true
          },
          'financialTab=trust': { title: 'Trust Accounting', stop: true },
          'financialTab=commissions': { title: 'Commissions', stop: true },
          'mode=financial': { title: 'VPA' }
        },
        stop: true
      },
      'lens=current': { title: 'Current' },
      'lens=sale': { title: 'For Sale' },
      'lens=rent': { title: 'For Rent' },
      'lens=sold': { title: 'Sold' },
      'lens=leased': { title: 'Leased' },
      'lens=withdrawn': { title: 'Withdrawn' }
    }
  },
  '^/projects/?': {
    title: 'Projects',
    sub: {
      'id=.*': { title: 'Record', stop: true }
    }
  },
  '^/project_stages/?': {
    title: 'Project Stages',
    sub: {
      'id=.*': { title: 'Record', stop: true }
    }
  },
  '^/properties/?': {
    title: 'Properties',
    sub: {
      'id=.*': { title: 'Record', stop: true },
      'lens=my_active_appraisals': { title: 'My Valuations' },
      'all=true': { title: 'All Properties' }
    }
  },
  '^/buildings/?': {
    title: 'Buildings',
    sub: {
      'id=.*': { title: 'Record', stop: true }
    }
  },
  '^/contacts/?': {
    title: 'Contacts',
    sub: {
      'id=.*': { title: 'Record', stop: true },
      'lens=my': { title: 'Mine' },
      'lens=assigned': { title: 'Owned by Me' },
      'all=true': { title: 'All' },
      'lens=companies': { title: 'Companies' }
    }
  },
  '^/leads/?': {
    title: 'Leads'
  },
  '^/market-leads/?': {
    title: 'Market Leads',
    sub: {
      'id=.*': { title: 'Record', stop: true },
      'lens=me': { title: 'Mine' },
      'lens=unassigned': { title: 'Unassigned' },
      'lens=others': { title: 'Others' },
      'all=true': { title: 'All' }
    }
  },
  '^/campaigns/?': {
    title: 'Campaigns',
    sub: {
      'id=.*': { title: 'Record', stop: true },
      'lens=me': { title: 'Mine' },
      'all=true': { title: 'All' }
    }
  },
  '^/trust/?': {
    title: 'Trusts',
    sub: {
      'id=.*': { title: 'Record', stop: true },
      accounts: { title: 'Accounts' },
      funds_requests: { title: 'Funds Requests' }
    }
  },
  '^/invoices/?': {
    title: 'Invoices',
    sub: {
      'lens=unpaid': { title: 'Unpaid' },
      'lens=overdue': { title: 'Overdue' }
    }
  },
  '^/bills/?': {
    title: 'Bills'
  },
  '^/transactions/?': {
    title: 'Transactions',
    sub: {
      'id=.*': { title: 'Record', stop: true },
      'lens=payments': { title: 'Payments' },
      'lens=refunds': { title: 'Refunds' },
      'lens=credits': { title: 'Credits' }
    }
  },
  '^/commissions/?': { title: 'Commissions' },
  '^/agent_ledgers/?': {
    title: 'Agent Ledgers',
    sub: {
      'id=.*': { title: 'Record', stop: true },
      ledgers: { title: 'Ledgers' },
      bulk_commissions: { title: 'Bulk Commissions' },
      pay_run: { title: 'Pay Run' }
    }
  },
  '^/scheduled-messages/?': {
    title: 'Scheduled Messages'
  },
  '^/messages/?': {
    title: 'Messages',
    sub: {
      'lens=sent': { title: 'Sent' },
      'lens=failed': { title: 'Failed to Send' }
    }
  },
  '^/workflows/?': {
    title: 'Workflows',
    sub: {
      'lens=awaiting': { title: 'Awaiting Input' },
      'lens=pending': { title: 'Pending' },
      'lens=completed': { title: 'Completed' },
      'lens=all': { title: 'All' }
    }
  },
  '^/scheduled_workflows/?': {
    title: 'Scheduled Workflows'
  },
  '^/referrals/?': {
    title: 'Referrals',
    sub: {
      'lens=me': { title: 'Mine' },
      'lens=team': { title: 'Team' },
      'lens=all': { title: 'All' }
    }
  },
  '^/audits/?': { title: 'Audit Log' },
  '^/tenancy-applications/?': { title: 'Tenancy Applications' },
  '^/notes': { title: 'Notes' },
  '^/reminders/?': { title: 'Reminders' },
  '^/feedback/?': { title: 'Feedback' },
  '^/appraisals/?': {
    title: 'Appraisals',
    sub: {
      'id=.*': { title: 'Record', stop: true }
    }
  },
  '^/contracts/?': { title: 'Contracts' },
  '^/chains/?': { title: 'Chains' },
  '^/holds/?': { title: 'Holds' },
  // '^/appointments/?': { title: 'Appointments' },
  '^/deals/?': {
    title: 'Deals',
    sub: {
      'lens=my': { title: 'Mine' },
      'all=true': { title: 'All' },
      'lens=active': { title: 'Active' },
      'lens=closed': { title: 'Closed' }
    }
  },
  '^/apps/?': { title: 'Applications' },
  '^/reporting/center/?': {
    title: 'Reporting',
    sub: {
      'category=activity': { title: 'Activity Reports' },
      'category=advertising': { title: 'Advertising / VPA' },
      'category=contracts': { title: 'Contract Statuses' },
      'category=contract_conditions': { title: 'Contract Conditions' },
      'category=contract_verifications': { title: 'Contract Verifications' },
      'category=commissions': { title: 'Commissions' },
      'category=invoices_and_income': { title: 'Invoices / Income' },
      'category=listings': { title: 'Listings & Uploads' },
      'category=listing_stock_lists': { title: 'Listing Stock Lists' },
      'category=listing_events': { title: 'OFI Lists' },
      'category=spicerhaart': { title: 'Spicerhaart Lists' },
      'category=agent_ledgers': { title: 'Agent Ledgers' }
    }
  },
  '^/newsletters/?': {
    title: 'Newsletters',
    sub: { 'id=.*': { title: 'Record', stop: true } }
  },
  '^/admin/?': {
    title: 'Admin',
    sub: {
      integrations: {
        title: 'Integrations',
        sub: { active: { title: 'Active' }, discover: { title: 'Discover' } }
      },
      deduplication: {
        title: 'Record Deduplication',
        sub: {
          'mode=contacts': { title: 'People' },
          'mode=companies': { title: 'Companies' }
        }
      },
      agency: {
        title: 'Agency Settings & Branding',
        sub: {
          account: { title: 'Agency Profile' },
          locations: { title: 'Locations' },
          sharing: { title: 'Account Sharing' },
          owner: { title: 'Account Owner' },
          billing: { title: 'Account Billing' },
          rexhome: { title: 'Rex Home Settings' }
        }
      },
      tabs: {
        title: 'Custom Fields and Tabs',
        sub: {
          'module=contacts': { title: 'Contact Tabs' },
          'module=properties': { title: 'Property Tabs' },
          'module=listings': { title: 'Listing Tabs' },
          'module=projects': { title: 'Project Tabs' },
          'module=project_stages': { title: 'Project Stage Tabs' },
          'module=contracts': { title: 'Contract Tabs' },
          'module=accounts': { title: 'Agency Tabs' },
          'module=admin_office_locations': { title: 'Location Tabs' },
          'module=market_leads': { title: 'Market Lead Tabs' },
          'module=account_users': { title: 'User Tabs' },
          'module=tenancy_applications': { title: 'Tenancy Application Tabs' }
        }
      },
      lists: {
        title: 'Custom Values & Mailing Lists',
        sub: {
          systemvalues: { title: 'Value Lists' },
          tags: { title: 'System Tags' },
          mailing: {
            title: 'Mailing Lists',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          }
        }
      },
      templates: {
        title: 'Tracks, Merge & Brochures',
        sub: {
          tracks: { title: 'Tracks' },
          merge: {
            title: 'Merge',
            sub: {
              'lens=email': { title: 'Email' },
              'lens=letter': { title: 'Letter' },
              'lens=sms': { title: 'SMS' }
            }
          },
          newsletters: {
            title: 'Newsletter Templates'
          },
          brochures: { title: 'Brochures' },
          filters: { title: 'Public Filters' }
        }
      },
      html_templates: {
        title: 'Advanced HTML Templates',
        sub: {
          '\\btemplates\\b': {
            title: 'Templates',
            sub: {
              'lens=newsletter': { title: 'Newsletter' },
              'lens=listing_update_email': { title: 'Match Emails' },
              'lens=ebrochure': { title: 'E-Brochure' }
            }
          },
          resources: { title: 'File Library' }
        }
      },
      custom_validation: { title: 'Custom Validation' },
      workflows: { title: 'Workflows' },
      embedded_apps: { title: 'Embedded Apps' },
      webhooks: {
        title: 'Webhooks',
        sub: { 'id=.*': { title: 'Record', stop: true } }
      },
      metric_write_rules: { title: 'Metric Write Rules' },
      identity: {
        title: 'ID Verification',
        sub: {
          id_types: { title: 'ID Types' },
          'contact-requirements': {
            title: 'Contact ID Requirements',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          'company-requirements': {
            title: 'Company ID Requirements',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          }
        }
      },
      users: {
        title: 'Users and Security',
        sub: {
          manage: {
            title: 'Rex Users',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          permission_groups: {
            title: 'Permission Groups',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          privilege_sets: {
            title: 'Privilege Sets',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          user_groups: {
            title: 'User Groups',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          }
        }
      },
      finance_and_commissions: {
        title: 'Finance & Commissions',
        sub: {
          chart_of_accounts: {
            title: 'Chart of Accounts'
          },
          account_defaults: {
            title: 'Account Defaults'
          },
          structures: {
            title: 'Commission Structures',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          sliding: {
            title: 'Agent Sliding Scale',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          deductions: {
            title: 'Deductions',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          agent: {
            title: 'Agent Defaults',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          }
        }
      },
      vpa: {
        title: 'Advert Providers & Packs',
        sub: {
          providers: {
            title: 'Advertising Providers',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          packs: {
            title: 'Advertising Packs',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          }
        }
      },
      portals: {
        title: 'Portals and Publication Defaults',
        sub: {
          profiles: { title: 'Portals' },
          custom: { title: 'Custom Feeds' },
          image_cycling_defaults: { title: 'Image Cycling' },
          targets: { title: 'Publication' },
          marketing_overrides: { title: 'Marketing Overrides' },
          watermarks: {
            title: 'Watermarks',
            sub: { 'id=.*': { title: 'Record', stop: true } }
          },
          document_admin: { title: 'Required Documents' },
          disclaimers: { title: 'Disclaimer' }
        }
      },
      auto: {
        title: 'Auto Update Settings',
        sub: {
          general: { title: 'General Settings' },
          overrides: { title: 'Content Overrides' }
        }
      },
      referrals: {
        title: 'Referral Settings',
        sub: {
          categories: {
            title: 'Referral Categories'
          }
        }
      },
      settings: {
        title: 'Advanced Settings',
        sub: {
          image: { title: 'Image Options' },
          finance: { title: 'Finance' },
          legal: { title: 'Legal' },
          campaign_templates: { title: 'Campaign Templates' },
          last_contacted: { title: 'Last Contacted' },
          open_homes: { title: 'Open Homes' },
          communication_settings: { title: 'Communication Settings' },
          'aml-settings': { title: 'AML Settings' },
          appointment_types: { title: 'Calendar Appointments' }
        }
      }
    }
  },
  '^/user/?': {
    title: 'User',
    sub: {
      profile: {
        title: 'My Profile',
        sub: {
          'page=details': { title: 'My Details' },
          'page=calendars': { title: 'Calendars' },
          'page=sync': { title: 'Contact Sync' },
          'page=dropbox': { title: 'Email Dropbox' },
          'page=leads': { title: 'Leads' },
          'page=notifications': { title: 'Notifications' },
          'page=delegate': { title: 'Delegate Input Requests' },
          'page=integrations': { title: 'Integrations' }
        }
      }
    }
  },
  '^/email_dropbox/?': { title: 'Email Dropbox' },
  '^/announcements/?': { title: 'Announcements' },
  '^/dashboards/?': { title: 'Dashboards' },
  '^/experiment/listings?': { title: 'Listings' },
  '^/experiment/projects?': { title: 'Projects' },
  '^/report/': {
    getTitle: ({ path }) => {
      // the last element will be an empty string if the last character in the path is a /
      // so filter that out
      const sub = path.split('/').filter(Boolean).pop();

      const moduleName = startCase(sub);
      return `${moduleName} Report`;
    }
  }
};
